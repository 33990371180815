import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'layouts';
import '../styles/prism';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import Constants from '../../static/Constants';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Analytic = ({ ...props }) => {
  const { pageContext } = props;
  const data = pageContext.pageData ? pageContext.pageData : null;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  if (!data) {
    return;
  }
  const [isWaipaMode, setIsWaipaMode] = useState(false);
  const [menuTabIndex, setMenuTabIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoader, setPdfLoader] = useState(true);

  const baseURL = Constants.apiUrl;
  const folderPath = '/resources/Analytics/';
  const programLink = pageContext.programUrl;
  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { headerLogo, headerLogoScroll, screenName } = data || {};

  const loading = false;

  const headerLogos = pageContext.logos || [];
  const { title, description, url, parallax, content, langKey } = data;
  const { t } = useTranslation();

  useEffect(() => {
    if (url && url.includes('waipa')) {
      setIsWaipaMode(true);
    } else {
      setIsWaipaMode(false);
    }
    setMenuTabIndex(0);
  }, [url]);

  const waipaMenu = [
    t('analytics_waipa_menu_1'),
    t('analytics_waipa_menu_2'),
    t('analytics_waipa_menu_3'),
  ];

  const waipaLinks = [
    'waipa_shortversion.pdf',
    'waipa_longversion.pdf',
    'waipa_newsletterleries.pdf',
  ];

  const getLinkByName = url => {
    let link = '';
    const urls = [
      {
        key: 'waipa',
        value: `${baseURL}/${folderPath}WAIPA/${waipaLinks[menuTabIndex]}`,
      },
      {
        key: 'workstream1',
        value: `${baseURL}/${folderPath}WorkStream1/ws1.1.pdf`,
      },
      {
        key: 'workstream2',
        value: `${baseURL}/${folderPath}WorkStream2/ws2.2.pdf`,
      },
    ];
    const brochure = urls.find(a => url.includes(a.key));
    if (brochure) {
      link = brochure.value;
    }
    return link;
  };

  const onMenuItemChange = index => {
    setMenuTabIndex(index);
    setPdfLoader(true);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setPdfLoader(false);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  // const download = objectUrl => {
  //   const link = document.createElement('a');
  //   link.setAttribute(
  //     'download',
  //     getLinkByName(url)
  //       .split('/')
  //       .pop()
  //   );
  //   link.setAttribute('href', objectUrl);
  //   link.click();
  // };
  // const downloadBlob = blob => download(URL.createObjectURL(blob));

  // const downloadPdf = url => {
  //   fetch(url)
  //     .then(response => response.blob())
  //     .then(downloadBlob);
  // };

  return (
    <Layout
      programLink={programLink}
      imageName={parallax}
      title={title == 'WAIPA' ? 'UNIDO-WAIPA Survey' : t(langKey)}
      content={[t(content)]}
      headerLinks={headerLinks}
      logos={headerLogos}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      visitedLinks={[
        { url: '/', key: 'home', name: 'Home' },
        { url: '/analytics', key: 'analytics', name: 'Analytics' },
      ]}
      currentPage={t(langKey)}
      screenName={screenName}
    >
      {data ? (
        <div className="sector-details container" style={{ marginTop: '0' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="sector-summary">
                {!isWaipaMode ? (
                  <>
                    <div className="sector-title">
                      <h4>{t(langKey)}</h4>
                    </div>
                    <div className="sector-description">
                      <p>{t(description)}</p>
                    </div>
                  </>
                ) : (
                  <div className="analytics-waipa-section">
                    <p>
                      <center>
                        <div className="sector-title">
                          <h4>Download the UNIDO-WAIPA Report here:</h4>
                        </div>
                      </center>
                    </p>
                    <center>
                      <a
                        href="https://downloads.unido.org/ot/32/67/32672501/UNIDO-WAIPA_Report.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          variant="contained"
                          size="large"
                          className="btn-primary"
                          // onClick={() => downloadPdf(getLinkByName(url))}
                        >
                          Click here
                        </Button>
                      </a>
                    </center>
                    <div className="analytics-waipa-section-details">
                      <p>
                        <center>
                          <span>
                            <b>
                              &quot;Investment Promotion and Facilitation in the
                              African, Caribbean and Pacific (ACP) Region&quot;
                            </b>
                          </span>
                          <br />
                          <span>
                            State-of-play, challenges and opportunities
                          </span>
                        </center>
                      </p>
                      <p>
                        <center>
                          <span>
                            Robust and equitable investment flows are crucial to
                            fostering sustainable development and inclusive
                            economic growth, especially in regions confronted
                            with unique challenges. In the African, Caribbean
                            and Pacific (ACP) region, home to almost 80% of
                            Least Developed Countries (LDCs), the promotion of
                            investment is essential in creating jobs, increasing
                            sustainable energy supply, and creating shared
                            prosperity.
                          </span>
                        </center>
                      </p>
                      <p>
                        <center>
                          <span>
                            Against this backdrop, UNIDO has collaborated with
                            its partner, the World Association of Investment
                            Promotion Agencies (WAIPA), to conduct a
                            first-of-its-kind survey across IPAs in the ACP
                            region. This report presents the empirical insights
                            of this survey, shedding light on the specific
                            characteristics and challenges of IPAs in the ACP
                            region and offering practical recommendations for
                            enhancing the effectiveness of their work.
                          </span>
                        </center>
                      </p>
                      <p>
                        <center>
                          <span>
                            Together, we can bring more sustainable investments
                            to ACP countries. Join UNIDO in scaling up
                            collective action.
                          </span>
                        </center>
                      </p>
                      <p>
                        <center>
                          <span>
                            UNIDO, Division of Fair Production, Sustainability
                            Standards and Trade
                          </span>
                          <br />
                          <span>
                            <a
                              className="links-blanks"
                              href="mailto:investmentportal@unido.org"
                            >
                              investmentportal@unido.org
                            </a>
                          </span>
                        </center>
                      </p>
                    </div>
                  </div>
                )}

                <GridContainer>
                  {isWaipaMode && (
                    <GridItem sm={12} md={3}>
                      <div className="analytic-menu">
                        {waipaMenu.map((ele, index) => {
                          return (
                            <div
                              key={index}
                              className={`analytic-menu-item ${
                                menuTabIndex == index
                                  ? 'analytic-menu-item-active'
                                  : ''
                              }`}
                              onClick={() => onMenuItemChange(index)}
                              role="presentation"
                            >
                              <span className="menu-title">{ele}</span>
                              <IconButton className="arrow">
                                <ArrowRightAltIcon />
                              </IconButton>
                            </div>
                          );
                        })}
                      </div>
                    </GridItem>
                  )}

                  <GridItem sm={12} md={isWaipaMode ? 9 : 12}>
                    {mobileScreen ? (
                      <div className="pdf-sector">
                        <a
                          href={getLinkByName(url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            variant="contained"
                            size="large"
                            className="btn-primary"
                            // onClick={() => downloadPdf(getLinkByName(url))}
                          >
                            {t('download')} PDF
                          </Button>
                        </a>
                      </div>
                    ) : (
                      <div>
                        {!pdfLoader && (
                          <>
                            <div className="pdftoolbar">
                              <div>
                                <IconButton
                                  className="arrowBack"
                                  disabled={pageNumber <= 1}
                                  onClick={previousPage}
                                >
                                  <ArrowBackIos />
                                </IconButton>
                                <span className="pdf-pagination">
                                  {pageNumber || (numPages ? 1 : '--')} /{' '}
                                  {numPages || '--'}{' '}
                                </span>
                                <IconButton
                                  disabled={pageNumber >= numPages}
                                  onClick={nextPage}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </div>
                              <div>
                                <a
                                  href={getLinkByName(url)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button
                                    className="btn-primary"
                                    variant="contained"
                                    // onClick={() =>
                                    //   downloadPdf(getLinkByName(url))
                                    // }
                                  >
                                    {t('download')} PDF
                                  </Button>
                                </a>
                              </div>
                            </div>
                            <hr className="horizonal-line" />
                          </>
                        )}
                        <Document
                          file={getLinkByName(url)}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className="docPdf"
                        >
                          <Page
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                          />
                        </Document>

                        {/* <iframe
                              className="analytic-pdf-viewer-analytic"
                              title="pdfviewer"
                              style={{
                                backgroundColor: '#2b3a8c',
                                width: '100%',
                                height: '700px',
                                marginTop: '15px',
                              }}
                              src={getLinkByName(url)}
                            /> */}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      ) : (
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ textAlign: 'center', padding: '20px' }}
          >
            <p>
              {loading
                ? t('Loading sector details')
                : t('No Information to display')}
            </p>
          </GridItem>
        </GridContainer>
      )}
      {/* <section style={{ marginBottom: '40px' }} className="quik-links">
        <div className="container">
          <QuickLinks programLink={programLink}></QuickLinks>
        </div>
      </section> */}
    </Layout>
  );
};

export default Analytic;

Analytic.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
